import { Comment } from "vue";

export default (): any => {
  const hasSlot = (slots: any) =>
    !!(
      slots.default &&
      slots.default().findIndex((o: any) => o.type !== Comment) !== -1
    );

  return {
    hasSlot,
  };
};
